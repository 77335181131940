<template>
  <v-card>
    <v-card-title>
      Cliente
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col
            cols="12"
          >
            <v-card outlined>
              <v-card-title>
                Dados Pessoais
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      class="py-0"
                      cols="12"
                    >
                      <v-text-field
                        v-model="client.client_name"
                        outlined
                        :readonly="edit"
                        dense
                        label="Nome"
                        required
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="6"
                    >
                      <v-text-field
                        v-model="client.cpf_cnpj"
                        outlined
                        :readonly="edit"
                        dense
                        label="CPF"
                        required
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="6"
                      md="3"
                    >
                      <v-select
                        v-model="client.person_type"
                        outlined
                        :readonly="edit"
                        dense
                        label="Entidade"
                        :items="['Física', 'Jurídica']"
                        required
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="6"
                      md="3"
                    >
                      <v-select
                        v-model="client.genre"
                        outlined
                        :readonly="edit"
                        dense
                        label="Gênero"
                        :items="['Masculino', 'Feminino']"
                        required
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="client.id_card_number"
                        outlined
                        :readonly="edit"
                        dense
                        label="RG"
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="6"
                      md="3"
                    >
                      <v-text-field
                        v-model="client.id_card_issuer"
                        outlined
                        :readonly="edit"
                        dense
                        label="Orgão emissor"
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="6"
                      md="3"
                    >
                      <v-text-field
                        v-model="client.id_card_issuance"
                        outlined
                        :readonly="edit"
                        dense
                        label="Data Emissão"
                        type="date"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
          >
            <v-card outlined>
              <v-card-title>
                Contato
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      class="py-0"
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="client.phone_cell"
                        outlined
                        :readonly="edit"
                        dense
                        label="Telefone Primário"
                        required
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="client.phone_cell_2"
                        outlined
                        dense
                        label="Telefone Secundário"
                        required
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="client.phone"
                        outlined
                        :readonly="edit"
                        dense
                        label="Celular Primário"
                        required
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="client.phone_2"
                        outlined
                        dense
                        label="Celular Secundário"
                        required
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      md="12"
                    >
                      <v-text-field
                        v-model="client.email"
                        outlined
                        dense
                        label="Email"
                        type="email"
                        required
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
          >
            <v-card outlined>
              <v-card-title>
                Endereço
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      class="py-0"
                      cols="10"
                      md="6"
                    >
                      <v-text-field
                        v-model="client.address"
                        outlined
                        :readonly="edit"
                        dense
                        label="Endereço"
                        required
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="2"
                      md="2"
                    >
                      <v-text-field
                        v-model="client.number"
                        outlined
                        :readonly="edit"
                        dense
                        label="nº"
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="4"
                      md="4"
                    >
                      <v-text-field
                        v-model="client.zip_code"
                        outlined
                        :readonly="edit"
                        dense
                        label="CEP"
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="12"
                      md="5"
                    >
                      <v-text-field
                        v-model="client.district"
                        outlined
                        :readonly="edit"
                        dense
                        label="Bairro"
                        required
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="12"
                      md="5"
                    >
                      <v-text-field
                        v-model="client.city"
                        outlined
                        :readonly="edit"
                        dense
                        label="Cidade"
                        required
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="2"
                      md="2"
                    >
                      <v-text-field
                        v-model="client.state"
                        outlined
                        :readonly="edit"
                        dense
                        label="UF"
                      />
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <h3>Endereço secundário</h3>
                  </v-row>
                  <v-divider class="mb-10">
                  </v-divider>
                  <v-row>
                    <v-col
                      class="py-0"
                      cols="10"
                      md="6"
                    >
                      <v-text-field
                        v-model="client.address_sec"
                        outlined
                        dense
                        label="Endereço"
                        required
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="2"
                      md="2"
                    >
                      <v-text-field
                        v-model="client.number_sec"
                        outlined
                        dense
                        label="nº"
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="4"
                      md="4"
                    >
                      <v-text-field
                        v-model="client.zip_code_sec"
                        outlined
                        dense
                        label="CEP"
                        :append-icon="icons.mdiSearchWeb"
                        @click:append="apiCorreios(true)"
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="12"
                      md="5"
                    >
                      <v-text-field
                        v-model="client.district_sec"
                        outlined
                        dense
                        label="Bairro"
                        required
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="12"
                      md="5"
                    >
                      <v-text-field
                        v-model="client.city_sec"
                        outlined
                        dense
                        label="Cidade"
                        required
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="2"
                      md="2"
                    >
                      <v-text-field
                        v-model="client.state_sec"
                        outlined
                        dense
                        label="UF"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
          >
            <v-card outlined>
              <v-card-title>
                Referências
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      class="py-0"
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="client.reference_name"
                        label="Nome"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="client.reference_phone"
                        label="Telefone"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="client.reference_name_2"
                        label="Nome"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="client.reference_phone_2"
                        label="Telefone"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col
            v-if="edit"
            cols="auto"
          >
            <v-btn
              color="info"
              @click="updateClient"
            >
              Salvar
            </v-btn>
          </v-col>
          <v-col
            v-else
            cols="auto"
          >
            <v-btn
              color="success"
              @click="storeClient"
            >
              Cadastrar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@axios'
import { mdiSearchWeb } from '@mdi/js'

export default {
  props: {
    client: {
      type: Object,
      require: true,
      default: () => {},
    },
    closeModal: {
      type: Function,
      default: () => {},
    },
    idClient: {
      type: String,
      default: '',
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        mdiSearchWeb,
      },
    }
  },

  created() {
  },
  methods: {
    async updateClient() {
      await axiosIns
        .put(`/api/v1/integration/client/update/${this.idClient}`, this.client)
        .then(async () => {
          await this.closeModal()
          await this.$swal({
            icon: 'success',
            title: 'Cliente atualizado!',
            text: 'Dasos do cliente atualizados!',
            showConfirmButton: false,
            timer: 2000,
          })
        })
        .catch(async error => {
          await this.closeModal()
          await this.$swal({
            icon: 'error',
            title: 'Erro ao cadastrar grupo!',
            showConfirmButton: false,
            text: error,
          })
        })
    },

    async storeClient() {
      await axiosIns
        .post('/api/v1/integration/client/store', this.client)
        .then(async () => {
          await this.closeModal()
          await this.$swal({
            icon: 'success',
            title: 'Cliente cadastrado!',
            text: 'Novo cliente cadastrado!',
            showConfirmButton: false,
            timer: 2000,
          })
        })
        .catch(async error => {
          await this.closeModal()
          await this.$swal({
            icon: 'error',
            title: 'Erro ao cadastrar grupo!',
            showConfirmButton: false,
            text: error,
          })
        })
    },

    apiCorreios(data) {
      let cep = ''
      if (data) {
        cep = this.client.zip_code_sec
      } else {
        cep = this.client.zip_code
      }

      axiosIns.get(`https://viacep.com.br/ws/${cep}/json/`).then(res => {
        if (data) {
          this.client.address_sec = res.data.logradouro
          this.client.district_sec = res.data.bairro
          this.client.city_sec = res.data.localidade
          this.client.state_sec = res.data.uf
        } else {
          this.client.address = res.data.logradouro
          this.client.district = res.data.bairro
          this.client.city = res.data.localidade
          this.client.state = res.data.uf
        }
      })
    },
  },
}
</script>

<style>
</style>
