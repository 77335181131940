<template>
  <div>
    <v-card>
      <v-card-title>
        Clientes
      </v-card-title>

      <v-divider class="mt-0"></v-divider>

      <!-- filter quicksearch -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="filterOptions.client_name"
          label="Buscar Cliente"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filterOptions.date_bordero"
              class="mb-4"
              dense
              label="Mês referência"
              hide-details
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterOptions.date_bordero"
            type="month"
            width="270"
            locale="pt-BR"
          ></v-date-picker>
        </v-menu>
        <!-- <v-text-field
          v-model="filterOptions.client_name"
          label="Buscar Cliente"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field> -->

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click="filterClientName"
          >
            <v-icon>{{ icons.mdiFilter }}</v-icon>
            <span>Filtrar</span>
          </v-btn>

          <v-btn
            color="secondary"
            outlined
            class="mb-4"
            @click="filterReset"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiFilterRemove }}
            </v-icon>
            <span>Limpar</span>
          </v-btn>
        </div>
      </v-card-text>

      <!--table-->
      <v-data-table
        :headers="headers"
        :items="clientList"
        :loading="loading"
        loading-text="Carregando dados..."
        :page.sync="pageOptions.page"
        :items-per-page="pageOptions.itemsPerPage"
        fixed-header
        :height="heightDinamic"
        hide-default-footer
        disable-sort
        @page-count="pageCount = $event"
      >
        <!-- action (edit, delete, etc) -->
        <template #[`item.actions`]="{ item }">
          <v-icon
            v-if="item.id === loadModal? false : true"
            medium
            class="me-2"
            @click="getClientInfo(item)"
          >
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <v-progress-circular
            v-if="item.id === loadModal? true : false"
            indeterminate
            :size="20"
            color="gray"
          ></v-progress-circular>
        </template>
        <!--data empty-->
        <template v-slot:no-data>
          {{ noDataText }}
        </template>

        <!--field phone_cell-->
        <template #[`item.phone`]="{item}">
          <v-icon
            medium
            @click="chatWhatsapp(item.phone)"
          >
            {{ icons.mdiWhatsapp }}
          </v-icon>
          {{ item.phone }}
        </template>

        <template #[`item.contract_status`]="{item}">
          <v-alert
            text
            class="badge"
            :color="
              item.contract_status.trim() === 'PRE-CONTEMPLADO' ? 'info' :
              item.contract_status.trim() === 'PRE-CANCELADO' ? 'purple' :
              item.contract_status.trim() === 'CANCELADO' ? 'error' :
              item.contract_status.trim() === 'CANCELADO NO GRUPO' ? 'error' :
              item.contract_status.trim() === 'NORMAL' ? 'success' :
              item.contract_status.trim() === 'CONTEMPLADO' ? 'success' :
              ''
            "
          >
            {{ item.contract_status }}
          </v-alert>
        </template>

        <template #[`item.group_number`]="{item}">
          <span>{{ item.group_number }} {{ item.quota_number }} {{ item.reposition }} {{ item.quota_digit }}</span>
        </template>
      </v-data-table>

      <v-divider class="mt-4"></v-divider>

      <!-- pagination -->
      <v-card-text class="pt-2 pb-1">
        <v-row>
          <v-col
            lg="12"
            cols="12"
            class="d-flex justify-center"
          >
            <v-pagination
              v-model="pageOptions.page"
              total-visible="6"
              :length="pageOptions.pageCount"
              @input="updatePage"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="modal"
      width="1200"
    >
      <modal-client
        :id-client="idClient"
        :client="client"
        :close-modal="closeModal"
        :edit="edit"
      />
    </v-dialog>
  </div>
</template>

<script>
import axiosIns from '@axios'
import {
  mdiAccountOutline, mdiCalendarBlankOutline, mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiFilter,
  mdiFilterRemove,
  mdiPencilOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiWhatsapp,
} from '@mdi/js'
import modalClient from './modalClient.vue'

export default {
  components: {
    modalClient,
  },
  data: () => ({
    date_bordero: '',
    dateMenu: false,
    dialog: false,
    dialogDelete: false,
    icons: {
      mdiCalendarBlankOutline,
      mdiSquareEditOutline,
      mdiDotsVertical,
      mdiPencilOutline,
      mdiFileDocumentOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiExportVariant,
      mdiAccountOutline,
      mdiFilter,
      mdiFilterRemove,
      mdiWhatsapp,
    },

    /* filter */
    filterOptions: {
      client_name: '',
      date_bordero: '',
    },

    /* data table */
    clientList: [],
    noDataText: 'Nenhum registro encontrado',
    heightDinamic: '600px',
    loading: true,
    headers: [
      { text: 'NOME', value: 'client_name' },
      { text: 'STATUS', value: 'contract_status', align: 'center' },
      { text: 'GRUPO', value: 'group_number', align: 'center' },
      { text: 'CELULAR', value: 'phone_cell', align: 'center' },
      { text: 'CELULAR 2º', value: 'phone_cell_2', align: 'center' },
      { text: 'TELEFONE', value: 'phone', align: 'center' },
      { text: 'TELEFONE 2º', value: 'phone_2', align: 'center' },
      { text: 'OPÇÕES', value: 'actions', align: 'end' },
    ],

    /* router */
    routeOptions: {
      resource: 'index',
      method: 'get',
      params: {},
    },

    /* pagination */
    pageOptions: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
    },

    /* modal */
    loadModal: false,
    modal: false,
    idClient: '',
    client: {},
    edit: false,
    load: true,
  }),

  watch: {
    //
  },

  created() {
    this.initialize()
  },

  methods: {
    async initialize() {
      await axiosIns
        .get('/api/v1/integration/client/index')
        .then(res => {
          this.heightDinamic = '600px'
          this.clientList = res.data.data
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.noDataText = `Contate o suporte do sistema: ${err}`
        })
    },

    async updatePage() {
      this.loading = true
      this.clientList = []
      if (this.routeOptions.method === 'get') {
        await axiosIns
          .get(`/api/v1/integration/client/${this.routeOptions.resource}?page=${this.pageOptions.page}`)
          .then(res => {
            this.clientList = res.data.data
            this.pageOptions.itemsPerPage = res.data.meta.per_page
            this.pageOptions.pageCount = res.data.meta.last_page
            this.loading = false
          })
      } else if (this.routeOptions.method === 'post') {
        await axiosIns
          .post(
            `/api/v1/integration/client/${this.routeOptions.resource}?page=${this.pageOptions.page}`,
            this.routeOptions.params,
          )
          .then(res => {
            this.clientList = res.data.data
            this.pageOptions.itemsPerPage = res.data.meta.per_page
            this.pageOptions.pageCount = res.data.meta.last_page
            this.loading = false
          })
      }
    },

    async filterClientName() {
      this.loading = true
      this.routeOptions = {
        resource: 'filter_client_name',
        method: 'post',
        params: this.filterOptions,
      }
      this.clientList = []
      await axiosIns
        .post('/api/v1/integration/client/filter_client_name', this.routeOptions.params)
        .then(res => {
          this.clientList = res.data.data
          this.pageOptions.page = res.data.meta.current_page
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.noDataText = `Contate o suporte do sistema: ${err}`
        })
    },

    async filterReset() {
      this.loading = true
      this.clientList = []
      Object.keys(this.filterOptions).forEach(key => {
        this.filterOptions[key] = ''
      })
      this.routeOptions = {
        resource: 'index',
        method: 'get',
        params: {},
      }

      this.initialize()
    },

    chatWhatsapp(phone) {
      window.open(`https://wa.me/55${phone}`, '_blank')
    },

    /* modal methods */
    async getClientInfo(item) {
      this.loadModal = item.id
      this.idClient = item.id
      this.modal = true
      this.edit = true
      await axiosIns.get(`/api/v1/integration/client/edit/${item.id}`).then(res => {
        this.client = res.data.data
        this.modal = true
      })
      this.loadModal = false
    },

    async closeModal() {
      this.modal = false
      await this.clearModal()
      await this.next()
    },

    async openModal() {
      this.modal = true
      this.edit = false
      this.clearModal()
    },
  },
}
</script>
<style>
.badge{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 40px;
}
</style>
