import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardTitle,[_vm._v(" Clientes ")]),_c(VDivider,{staticClass:"mt-0"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"user-search me-3 mb-4",attrs:{"label":"Buscar Cliente","outlined":"","hide-details":"","dense":""},model:{value:(_vm.filterOptions.client_name),callback:function ($$v) {_vm.$set(_vm.filterOptions, "client_name", $$v)},expression:"filterOptions.client_name"}}),_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({staticClass:"mb-4",attrs:{"dense":"","label":"Mês referência","hide-details":"","readonly":"","outlined":""},model:{value:(_vm.filterOptions.date_bordero),callback:function ($$v) {_vm.$set(_vm.filterOptions, "date_bordero", $$v)},expression:"filterOptions.date_bordero"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c(VDatePicker,{attrs:{"type":"month","width":"270","locale":"pt-BR"},model:{value:(_vm.filterOptions.date_bordero),callback:function ($$v) {_vm.$set(_vm.filterOptions, "date_bordero", $$v)},expression:"filterOptions.date_bordero"}})],1),_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":_vm.filterClientName}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiFilter))]),_c('span',[_vm._v("Filtrar")])],1),_c(VBtn,{staticClass:"mb-4",attrs:{"color":"secondary","outlined":""},on:{"click":_vm.filterReset}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterRemove)+" ")]),_c('span',[_vm._v("Limpar")])],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.clientList,"loading":_vm.loading,"loading-text":"Carregando dados...","page":_vm.pageOptions.page,"items-per-page":_vm.pageOptions.itemsPerPage,"fixed-header":"","height":_vm.heightDinamic,"hide-default-footer":"","disable-sort":""},on:{"update:page":function($event){return _vm.$set(_vm.pageOptions, "page", $event)},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.id === _vm.loadModal? false : true)?_c(VIcon,{staticClass:"me-2",attrs:{"medium":""},on:{"click":function($event){return _vm.getClientInfo(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")]):_vm._e(),(item.id === _vm.loadModal? true : false)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":20,"color":"gray"}}):_vm._e()]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.noDataText)+" ")]},proxy:true},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"medium":""},on:{"click":function($event){return _vm.chatWhatsapp(item.phone)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiWhatsapp)+" ")]),_vm._v(" "+_vm._s(item.phone)+" ")]}},{key:"item.contract_status",fn:function(ref){
var item = ref.item;
return [_c(VAlert,{staticClass:"badge",attrs:{"text":"","color":item.contract_status.trim() === 'PRE-CONTEMPLADO' ? 'info' :
            item.contract_status.trim() === 'PRE-CANCELADO' ? 'purple' :
            item.contract_status.trim() === 'CANCELADO' ? 'error' :
            item.contract_status.trim() === 'CANCELADO NO GRUPO' ? 'error' :
            item.contract_status.trim() === 'NORMAL' ? 'success' :
            item.contract_status.trim() === 'CONTEMPLADO' ? 'success' :
            ''}},[_vm._v(" "+_vm._s(item.contract_status)+" ")])]}},{key:"item.group_number",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.group_number)+" "+_vm._s(item.quota_number)+" "+_vm._s(item.reposition)+" "+_vm._s(item.quota_digit))])]}}],null,true)}),_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"pt-2 pb-1"},[_c(VRow,[_c(VCol,{staticClass:"d-flex justify-center",attrs:{"lg":"12","cols":"12"}},[_c(VPagination,{attrs:{"total-visible":"6","length":_vm.pageOptions.pageCount},on:{"input":_vm.updatePage},model:{value:(_vm.pageOptions.page),callback:function ($$v) {_vm.$set(_vm.pageOptions, "page", $$v)},expression:"pageOptions.page"}})],1)],1)],1)],1),_c(VDialog,{attrs:{"width":"1200"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('modal-client',{attrs:{"id-client":_vm.idClient,"client":_vm.client,"close-modal":_vm.closeModal,"edit":_vm.edit}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }